var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile__user-pass-change-wrapper"},[_c('AvatarUploader',{staticClass:"profile__avatar",attrs:{"item":_vm.avatar[_vm.PROFILE_FORM_FIELD_API_ALIASES.PHOTO],"disabled":true}}),_c('div',{staticClass:"profile__user-form-tabs"},[_c('span',{staticClass:"profile__user-form-tab",class:{ 'profile__user-form-tab--active': _vm.activeTab === 'info' },on:{"click":function($event){return _vm.handleEmitTab('info')}}},[_vm._v("Information")]),_c('span',{staticClass:"profile__user-form-tab",class:{ 'profile__user-form-tab--active': _vm.activeTab === 'pass' },on:{"click":function($event){return _vm.handleEmitTab('pass')}}},[_vm._v("Change Password")])]),_c('v-form',{ref:"form",staticClass:"profile__change-password-form",attrs:{"disabled":_vm.disabled}},[_c('FormGroup',{staticClass:"profile__form-group profile__form-group--change-password"},[_c('v-text-field',{attrs:{"disabled":_vm.disabled,"label":"Enter old or temporary password","rules":['required', 'password'],"value":_vm.form[_vm.PROFILE_FORM_PASSWORD_FIELD_API_ALIASES.CURRENT_PASSWORD],"type":"password"},on:{"input":function($event){
var _obj;
return _vm.handleFieldInput('form', Object.assign({}, _vm.form,
            ( _obj = {}, _obj[_vm.PROFILE_FORM_PASSWORD_FIELD_API_ALIASES.CURRENT_PASSWORD] = arguments[0], _obj )))}}}),_c('v-text-field',{attrs:{"disabled":_vm.disabled,"label":"Create a new password","value":_vm.form[_vm.PROFILE_FORM_PASSWORD_FIELD_API_ALIASES.NEW_PASSWORD],"type":"password","rules":['required', 'password']},on:{"input":function($event){
          var _obj;
return _vm.handleFieldInput('form', Object.assign({}, _vm.form,
            ( _obj = {}, _obj[_vm.PROFILE_FORM_PASSWORD_FIELD_API_ALIASES.NEW_PASSWORD] = arguments[0], _obj )))}}}),_c('v-text-field',{attrs:{"disabled":_vm.disabled,"value":_vm.form[_vm.PROFILE_FORM_PASSWORD_FIELD_API_ALIASES.CONFIRM_PASSWORD],"type":"password","label":"Confirm your new password","rules":['required'],"errorMessages":_vm.passwordMatchErrorMessage},on:{"input":function($event){
          var _obj;
return _vm.handleFieldInput('form', Object.assign({}, _vm.form,
            ( _obj = {}, _obj[_vm.PROFILE_FORM_PASSWORD_FIELD_API_ALIASES.CONFIRM_PASSWORD] = arguments[0], _obj )))}}})],1)],1),_c('div',{staticClass:"profile__change-password-disclaimer"},[_vm._v(" Password must be at least 8 characters, contain numbers, capital letters, lowercase letters, extra characters and must not match the name and e-mail ")]),_c('div',{staticClass:"profile__footer"},[_c('v-btn',{staticClass:"profile__footer-button",attrs:{"disabled":_vm.disabled,"text":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"profile__footer-button",attrs:{"disabled":_vm.disabled},on:{"click":_vm.handleSubmitButton}},[_vm._v(" Save ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }