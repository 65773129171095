<template>
  <div class="profile__user-pass-change-wrapper">
    <AvatarUploader
      :item="avatar[PROFILE_FORM_FIELD_API_ALIASES.PHOTO]"
      :disabled="true"
      class="profile__avatar"
    />
    <div class="profile__user-form-tabs">
      <span
        class="profile__user-form-tab"
        :class="{ 'profile__user-form-tab--active': activeTab === 'info' }"
        @click="handleEmitTab('info')"
        >Information</span
      >
      <span
        class="profile__user-form-tab"
        :class="{ 'profile__user-form-tab--active': activeTab === 'pass' }"
        @click="handleEmitTab('pass')"
        >Change Password</span
      >
    </div>
    <v-form :disabled="disabled" ref="form" class="profile__change-password-form">
      <FormGroup class="profile__form-group profile__form-group--change-password">
        <v-text-field
          :disabled="disabled"
          label="Enter old or temporary password"
          :rules="['required', 'password']"
          :value="form[PROFILE_FORM_PASSWORD_FIELD_API_ALIASES.CURRENT_PASSWORD]"
          type="password"
          @input="
            handleFieldInput('form', {
              ...form,
              [PROFILE_FORM_PASSWORD_FIELD_API_ALIASES.CURRENT_PASSWORD]: arguments[0],
            })
          "
        />
        <v-text-field
          :disabled="disabled"
          label="Create a new password"
          :value="form[PROFILE_FORM_PASSWORD_FIELD_API_ALIASES.NEW_PASSWORD]"
          type="password"
          @input="
            handleFieldInput('form', {
              ...form,
              [PROFILE_FORM_PASSWORD_FIELD_API_ALIASES.NEW_PASSWORD]: arguments[0],
            })
          "
          :rules="['required', 'password']"
        />
        <v-text-field
          :disabled="disabled"
          :value="form[PROFILE_FORM_PASSWORD_FIELD_API_ALIASES.CONFIRM_PASSWORD]"
          type="password"
          @input="
            handleFieldInput('form', {
              ...form,
              [PROFILE_FORM_PASSWORD_FIELD_API_ALIASES.CONFIRM_PASSWORD]: arguments[0],
            })
          "
          label="Confirm your new password"
          :rules="['required']"
          :errorMessages="passwordMatchErrorMessage"
        />
      </FormGroup>
    </v-form>
    <div class="profile__change-password-disclaimer">
      Password must be at least 8 characters, contain numbers, capital letters, lowercase letters,
      extra characters and must not match the name and e-mail
    </div>
    <div class="profile__footer">
      <v-btn :disabled="disabled" class="profile__footer-button" text @click="$router.go(-1)">
        Cancel
      </v-btn>
      <v-btn :disabled="disabled" @click="handleSubmitButton" class="profile__footer-button">
        Save
      </v-btn>
    </div>
  </div>
</template>

<script>
import {
  PROFILE_FORM_PASSWORD_FIELD_API_ALIASES,
  PROFILE_FORM_FIELD_API_ALIASES,
} from '@/utils/constants';
import syncForm from '@/mixins/syncForm';
import validateForm from '@/mixins/validateForm';
import isValidString from '@/utils/helpers/string/isValidString';

const AvatarUploader = () => import('@/components/AvatarUploader');

const FormGroup = () => import('@/components/FormGroup');

export default {
  name: 'ProfileChangePasswordForm',
  components: {
    FormGroup,
    AvatarUploader,
  },
  props: {
    disabled: {
      type: [Boolean],
      default: () => false,
    },
    requestInProgress: {
      type: [Boolean],
      default: () => false,
    },
    avatar: {
      type: Object,
      default: () => '',
    },
    form: {
      type: Object,
      default: () => '',
    },
    activeTab: String,
  },
  mixins: [syncForm, validateForm],
  data: () => ({
    PROFILE_FORM_PASSWORD_FIELD_API_ALIASES,
    PROFILE_FORM_FIELD_API_ALIASES,
  }),
  computed: {
    passwordConfirmErrors() {
      return !!this.passwordMatchErrorMessage.length;
    },
    passwordMatchErrorMessage() {
      return isValidString(this.form[PROFILE_FORM_PASSWORD_FIELD_API_ALIASES.CONFIRM_PASSWORD]) &&
        this.form[PROFILE_FORM_PASSWORD_FIELD_API_ALIASES.CONFIRM_PASSWORD] !==
          this.form[PROFILE_FORM_PASSWORD_FIELD_API_ALIASES.NEW_PASSWORD]
        ? "The passwords don't match"
        : '';
    },
  },
  methods: {
    handleEmitTab(tab) {
      this.$emit('changeTab', tab);
    },
    handleSubmitButton() {
      const valid = this.validate();
      if (valid && !this.passwordConfirmErrors) {
        this.$emit('submit');
      }
    },
    validate() {
      if (this.$refs.form) {
        return this.$refs.form.validate();
      }
      throw new Error("The form wasn't mounted");
    },
  },
};
</script>

<style lang="scss" scope>
.profile__user-pass-change-wrapper {
  width: 100%;
}
.profile__change-password-form {
  width: 100%;
}
.profile__form-group {
  width: 100%;
}
.profile__avatar {
  margin: 34px 0 23px;
}
.profile__change-password-disclaimer {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 1px;
  color: #9d9ea0;
}
.profile__user-form-tab {
  color: #949494;
  font-size: 18px;
  font-weight: 500;
  padding: 0 6px;
  line-height: 42px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  transition: 0.3s all ease-in-out;
  &:hover {
    color: #000000;
  }
  &--active {
    color: #000000;
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      right: 50%;
      transform: translateX(50%);
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, #7aee5d 0%, #1fe08d 100%);
    }
  }
}
.profile__footer-button {
  width: 165px;
}
.profile__footer {
  margin: 24px 0 0;
  display: flex;
  justify-content: space-around;
}
</style>
