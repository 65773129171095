import { render, staticRenderFns } from "./ProfileChangePasswordForm.vue?vue&type=template&id=2df9922a&"
import script from "./ProfileChangePasswordForm.vue?vue&type=script&lang=js&"
export * from "./ProfileChangePasswordForm.vue?vue&type=script&lang=js&"
import style0 from "./ProfileChangePasswordForm.vue?vue&type=style&index=0&lang=scss&scope=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import VBtn from '@/components/HOC/VBtn'
import { VForm } from 'vuetify/lib/components/VForm';
import VTextField from '@/components/HOC/VTextField'
installComponents(component, {VBtn,VForm,VTextField})
